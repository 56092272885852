.laatste-nieuws-tegel {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &__status {
        width: 15%;
        display: flex;
        align-self: center;
        justify-content: center;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        color: #2eae4a;
        background-color: rgba(108, 185, 129, 0.3);
        line-height: 2;
    }

    &__body {
        width: 75%;
    }

    &__link {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    &__headline {
        font-size: 14px;
    }

    &__date {
        font-size: 14px;
        padding-bottom: .5rem;
    }

    &__hr {
        background-color: var(--gray);
        opacity: .25;
        border: none;
        height: 1px;
        margin: 0;
        padding: 0;
    }
}