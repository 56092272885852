/*------------------------------------------------------------------------------------------------- 
    Buttons
--------------------------------------------------------------------------------------------------*/

.button-container {
  @include flex();
  justify-content: left;
}

.btn {
  font-family: 'Roboto';
  padding: 9px 16px;
  border-radius: 4px;
  font-weight: 500;
  border-color: transparent;
  font-size: var(--font-btn);
  text-decoration: none;
  outline: none;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  @include transition(none);

  &-primary {
    color: var(--white);
    background: #397DCB;
    @include transition(none);

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: #397DCB;
      border-color: transparent;
    }

    &.disabled,
    &:disabled {
      background: var(--white);
      border: 1px solid var(--mid-grey);
      color: var(--darkest-grey);

      &:focus {
        box-shadow: none;
      }

      &:hover,
      &:active:focus {
        border: 1px solid var(--mid-grey);
        opacity: 0.65;
        background: var(--white);
        cursor: default;
      }
    }
  }

  &-delete {
    color: var(--white);
    background: var(--validation-red-text);
    @include transition(none);

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: darken($validation-red-hover-color, 7%);
      color: var(--white);
      border-color: transparent;
    }
  }

  &-pagination {
    display: inline-block;
    padding: 6px 0;
    text-align: center;
    border-radius: 6px;
    margin-left: 5px;
    color: var(--dark-black);
    line-height: 12px;
    width: 25px;
    height: 25px;

    &-active {
      background-color: var(--dark-black);
      color: var(--white);
    }

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: var(--text-color);
      border-radius: 6px;
      background-color: var(--light-grey);
      border-color: var(--light-grey);
      box-shadow: none;
    }
  }

  &-outline-secondary {
    padding: 3px;
    margin-left: 5px;
    color: var(--text-color);
    line-height: 12px;
    width: 25px;
    height: 25px;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: var(--text-color);
      border-radius: 6px;
      background-color: var(--light-grey);
      border-color: var(--light-grey);
      box-shadow: none;
    }

    &.is-invalid {
      border-color: var(--validation-bg-platform);
      color: var(--validation-red-text);

      &:hover,
      &:focus,
      &:active,
      &:active:hover,
      &:active:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus {
        background-color: var(--validation-bg-platform);
        color: var(--validation-red-text);
        border-color: var(--validation-bg-platform);
      }
    }
  }

  &-link {
    font-weight: 0;
    color: var(--text-color);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: var(--text-color);
      text-decoration: none;
      background-color: var(--mid-grey);
      border-color: var(--mid-grey);
    }
  }
}
