.navigation {
  position: absolute;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;

  &__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__left {
    display: flex;
    justify-content: left;
    width: 50%;
  }

  &__right {
    display: flex;
    justify-content: right;
    width: 50%;
  }

  &__toggle {
    :focus, :hover {
      background-color: gray!important;
    }
  }

  &__icon {
    color: white;
    padding: 0;
    margin: 0;
    margin-left: .5rem;
  }

  &__title {
    display: flex;
    justify-content: center;
  }

  &__text {
    font-weight: 600;
    color: white;
    margin-left: .5rem;
    margin-bottom: 0;
  }

  &__menu {
    width: 250px;
  }

  &__email {
    font-weight: bold;
  }

  &__link {
    color: black;
  }
}