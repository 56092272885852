.laatste-nieuws-card {
    background-color: var(--white);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    height: 480px;

    &__header {
        padding: 1.25rem;
        font-size: var(--font-h3);
        font-weight: bold;
        text-align: center;
    }

    &__body {
        border-radius: 2px;
        margin: .2rem;
        overflow-y: scroll;
        justify-content: center;
        min-height: 320px;
        max-height: 380px;

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: var(--font-p);
            opacity: .75;
        }

        &::-webkit-scrollbar {
          width: 0.7em;
          height: 0.7em;
        }
      
        &::-webkit-scrollbar-track {
          background: none;
        }
      
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 0.5em;
        }
      
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.2);
        }
    }

    &__footer {
        padding: 1.5rem;
        background-color: #F1F5FF;
        color: var(--dark-black);
        font-size: var(--font-p);
        font-weight: 600;

        :hover {
            text-decoration: none;
        }
    }

    &__icon {
        padding: .5rem;
    }
}