.card_item {
  display: inline-block;
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 1rem;

  .card_startpage {
    &:hover {
      text-decoration: none;
    }

    .text_container {
      background-color: #2B424A;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -1px 0px rgba(255, 255, 255, 0.15);
      width: 114px;
      padding: 2.5rem 1rem .25rem 1rem;

      &:hover {
        padding: 2.5rem 1rem .25rem 1rem;
      }
    }

    .text_font {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: var(--white);
      word-wrap: break-word;

      &:hover {
        color: var(--gray);
      }
    }

    .img {
      position: relative;
      z-index: 10;
      margin-bottom: -3rem;
      width: 96px;
    }
  }
}
