.nieuwsModalContent {
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;

  a {
    text-decoration: underline;
    color: var(--sidemenu-link-active-color);
  }
}
