.first_block {
  background: #162125;
  padding-bottom: 2rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: url('../assets/polygons.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 3rem);

  .header {
    display: flex;
    flex-wrap: wrap;
  }

  .left {
    width: 50%;
    padding-top: 2rem;
    padding-left: 2rem;
  }

  .right {
    width: 50%;
    display: flex;
    justify-content: right;
    padding-top: 2rem;
    padding-right: 2rem;
  }

  .image {
    width: 32px;
  }

  .greetings {
    font-size: var(--font-h1);
    font-family: var(--header-font);
    font-weight: 400;
    margin: 5.5rem 1rem .5rem 1rem;
    color: var(--white);
    line-height: 41px;
  }

  h3 {
    font-family: var(--text-font);
    font-size: var(--font-h2);
    font-weight: 600;
    line-height: 41px;
    color: var(--white);
  }

  .description {
    font-size: var(--font-p);
    line-height: 22px;
    text-align: center;
    color: var(--white);
    opacity: .4;
    padding-bottom: 2rem;
  }
}

.second_block {
  display: flex;
  justify-content: center;
  padding: 1rem;

  &_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0.2em;
  font-size: var(--font-p);
  text-decoration: none;
}
