.rente-tegel {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    &__status {
        width: 25%;
        display: flex;
        justify-content: center;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;

        &--rood {
            color: #d61919;
            background-color: rgba(214, 25, 25, 0.3);
            line-height: 2;
        }

        &--groen {
            color: #2eae4a;
            background-color: rgba(108, 185, 129, 0.3);
            line-height: 2;
        }

        &--geel {
            color: #cca000;
            background-color: rgba(241, 196, 15, 0.3);
            line-height: 2;
        }
    }

    &__body {
        width: 75%;
    }

    &__link {
        font-size: 18px;
        font-weight: 600;
    }

    &__date {
        font-size: 14px;
        padding-bottom: 1rem;
    }

    &__hr {
        background-color: var(--gray);
        opacity: .25;
        border: none;
        height: 1px;
        margin: 0;
        padding: 0;
    }
}